import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

const initialState: any = {
  chats: [],
};

interface IPayload {
  chat?: any;
  id?: number | string;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChat: (state, action: IAction) => {
      const newState: any = state;
      const isExist = newState?.chats.find(
        (x: any) => x?.id === action?.payload?.chat?.id,
      );
      if (isExist) {
        newState.chats = newState?.chats?.map((x: any) => {
          if (x?.id === action?.payload?.chat?.id) {
            return { ...x, isOpen: true };
          }
          return x;
        });
      } else {
        if (newState?.chats?.length === 4) {
          newState.chats = newState?.chats.shift();
        }
        newState.chats = [...newState.chats, action?.payload?.chat];
      }
    },
    toggleChat: (state, action: IAction) => {
      const newState = state;
      newState.chats = newState?.chats?.map((x: any) => {
        if (x?.id === action?.payload?.id) {
          return { ...x, isOpen: !x?.isOpen };
        }
        return x;
      });
    },
    removeChat: (state, action: IAction) => {
      const newState = state;
      newState.chats = newState?.chats?.filter(
        (x: any) => x?.id !== action?.payload?.id,
      );
    },
  },
});

export const { setChat, removeChat, toggleChat } = chatSlice.actions;

export const useChatActions = () => {
  const dispatch = useAppDispatch();
  return {
    toggleChat: (payload: IPayload) => dispatch(toggleChat(payload)),
    setChat: (payload: IPayload) => dispatch(setChat(payload)),
    removeChat: (payload: IPayload) => dispatch(removeChat(payload)),
  };
};
const { reducer } = chatSlice;
export default reducer;
