import { TypographyOptions } from "@mui/material/styles/createTypography";

import palette from "./palette";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4Bold: true;
    h5Bold: true;
    h6Bold: true;
    subtitle1Bold: true;
    subtitle2Bold: true;
    subtitle3: true;
    subtitle3SemiBold: true;
    subtitle4: true;
    body1Bold: true;
    body1: true;
    body2: true;
    body3: true;
    body4: true;
    title1: true;
    title2: true;
    title3: true;
    title2Bold: true;
    menu: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h4Bold: React.CSSProperties;
  h5Bold: React.CSSProperties;
  h6Bold: React.CSSProperties;
  subtitle1Bold: React.CSSProperties;
  subtitle2Bold: React.CSSProperties;
  subtitle3: React.CSSProperties;
  subtitle3SemiBold: React.CSSProperties;
  subtitle4: React.CSSProperties;
  body1Bold: React.CSSProperties;
  body3: React.CSSProperties;
  body4: React.CSSProperties;
  title1: React.CSSProperties;
  title2: React.CSSProperties;
  title3: React.CSSProperties;
  title2Bold: React.CSSProperties;
  menu: React.CSSProperties;
}

export default {
  fontFamily: "poppins",
  h1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: 1.8,
    letterSpacing: "0.003em",

    "@media (min-width:750px)": {
      fontSize: "2rem",
    },
    "@media (min-width:1150px)": {
      fontSize: "3.6rem",
    },
    "@media (min-width:1500px)": {
      fontSize: "3.8rem",
    },
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.4rem",
    lineHeight: "1.5",
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.8rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "3.2rem",
    },
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.4rem",
    lineHeight: "1.1",
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.6rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2.8rem",
    },
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.6rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2.4rem",
    },
  },
  h4Bold: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.005em",
    "@media (min-width:750px)": {
      fontSize: "1.6rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2.4rem",
    },
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.2,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.4rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2.4rem",
    },
  },
  h5Bold: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: "1.2rem",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    "@media (min-width:750px)": {
      fontSize: "1.4rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2.4rem",
    },
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: 1.2,
    letterSpacing: "0.005em",
    "@media (min-width:750px)": {
      fontSize: "1.3rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2rem",
    },
  },
  h6Bold: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.2rem",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    "@media (min-width:750px)": {
      fontSize: "1.3rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2rem",
    },
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: "1.1rem",
    lineHeight: 1.4,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.2rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "1.6rem",
    },
  },
  subtitle1Bold: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.1rem",
    lineHeight: "1.5",
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.2rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "1.6rem",
    },
  },
  subtitle2: {
    color: palette.text.tertiary,
    fontWeight: 500,
    fontSize: "1.4rem",
    lineHeight: 1.4,
    letterSpacing: "0.02em",
  },
  subtitle2Bold: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.1rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  subtitle3: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: "1.1rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  subtitle3SemiBold: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.1rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  subtitle4: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "1.3rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.2rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "1.4rem",
    },
  },
  body1Bold: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.2rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "1.4rem",
    },
  },
  body2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.1rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.2rem",
    },
  },

  body3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
  body4: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "1.1rem",
    lineHeight: 1.1,
    letterSpacing: "0.02em",
  },
  caption: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "0.9rem",
    lineHeight: 1.2,
  },

  title1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.4rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.5rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "2rem",
    },
  },
  title2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.4,
    letterSpacing: "0.02em",
    "@media (min-width:750px)": {
      fontSize: "1.4rem",
    },
    "@media  (min-width:1150px)": {
      fontSize: "1.6rem",
    },
  },
  title3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    "@media  (min-width:750px)": {
      fontSize: "1.4rem",
    },
    "@media  (min-width:1200px)": {
      fontSize: "1.8rem",
    },
  },
  title2Bold: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: "2.8rem",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    "@media  (min-width:750px)": {
      fontSize: "1.4rem",
    },
    "@media  (min-width:1200px)": {
      fontSize: "1.8rem",
    },
  },
  menu: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "1.4rem",
    lineHeight: 1.5,
    letterSpacing: "0.02em",
  },
} as ExtendedTypographyOptions;
