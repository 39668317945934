import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from "@mui/material";
import palette from "theme/palette";

type IProps = {
  open: boolean;
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
  handleClose: () => void;
  onConfirm: () => void;
};

function Alert(props: IProps) {
  const {
    open,
    title,
    message,
    handleClose,
    onConfirm,
    cancelText,
    confirmText,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      PaperProps={{ sx: { borderRadius: "15px" } }}
      sx={{
        border: "1px solid #000",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <HelpOutlineOutlinedIcon
          sx={{
            fontSize: "45px",
            fontWeight: 400,
            color: palette.secondary.main,
          }}
        />
        {title}
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ fontWeight: "400" }}>{message}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "row",
          height: "100%",
          padding: "0px",
        }}
      >
        <Button
          color="secondary"
          sx={{ fontSize: "14px", m: 1 }}
          variant="text"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
        <Divider flexItem orientation="vertical" />
        <Box>
          <Button
            color="primary"
            sx={{ fontSize: "14px", m: 1 }}
            variant="text"
            onClick={handleClose}
          >
            {cancelText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
