import "./index.css";

import { StyledEngineProvider, ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "components/Snackbar";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";

import { AlertProvider } from "components/Alert";
import App from "./App";
import "./languages";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

// const newTheme = responsiveFontSizes(theme);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <AlertProvider>
                <QueryClientProvider client={queryClient}>
                  <Router>
                    <App />
                  </Router>
                </QueryClientProvider>
              </AlertProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
