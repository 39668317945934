import ErrorBoundary from "components/ErrorBoundary";
import Notification from "components/NotificationToaster";
import Routes from "navigation";

function App() {
  return (
    <ErrorBoundary>
      <>
        <Routes />
        <Notification />
      </>
    </ErrorBoundary>
  );
}

export default App;
