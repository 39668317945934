import {createSlice} from "@reduxjs/toolkit";
import {IJobPost} from "interfaces/job.interface";
import {useAppDispatch} from "redux/store";

const initialState: any = {
  jobsPost: null,
  jobDetails: null,
};

interface IPayload {
  jobsPost: IJobPost | null;
  jobDetails?: any | null;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const authSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    pushJobData: (state, action: IAction) => {
      const newState = state;
      newState.jobsPost = action.payload.jobsPost;
    },

    setJobData: (state, action: IAction) => {
      const newState = state;
      newState.jobDetails = action.payload.jobDetails;
      newState.jobsPost = action.payload.jobsPost;
    },
  },
});

export const { pushJobData, setJobData } = authSlice.actions;

export const useJobActions = () => {
  const dispatch = useAppDispatch();
  return {
    setJobData: (payload: IPayload) => dispatch(setJobData(payload)),
    pushJobData: (payload: IPayload) => dispatch(pushJobData(payload)),
  };
};
const { reducer } = authSlice;
export default reducer;
