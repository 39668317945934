// import ProtectedRoute from "components/ProtectedRoute";
// import useUserInfo from "hooks/useUserInfo";
import ProtectedRoute from "components/ProtectedRoute";
import useDecodedData from "hooks/useDecodedData";
import useUserInfo from "hooks/useUserInfo";
import { Route, Routes } from "react-router-dom";
import * as AdminLoadable from "./ScreenContainer/AdminLoad";
import * as AuthLoadable from "./ScreenContainer/AuthLoad";
import * as UserLoadable from "./ScreenContainer/UserLoad";
import AppRoutes from "./appRoutes";

function Application() {
  const { isLoggedIn } = useUserInfo();
  const decoded = useDecodedData();
  return (
    <Routes>
      {isLoggedIn ? (
        <Route element={<ProtectedRoute />}>
          {decoded?.role === 1 ? (
            <Route
              element={<AdminLoadable.Dashboard />}
              path={AppRoutes.DASHBOARD}
            />
          ) : (
            <Route
              element={<AdminLoadable.OrgDashboard />}
              path={AppRoutes.ORG_DASHBOARD}
            />
          )}

          <Route
            element={<AdminLoadable.AnalysticsAll />}
            path={AppRoutes.ANALYSTICS_ALL}
          />

          <Route
            element={<AdminLoadable.Pages />}
            path={AppRoutes.COMPANY_PAGE}
          />
          <Route
            element={<AdminLoadable.Pages />}
            path={`${AppRoutes.COMPANY_PAGE}/:id`}
          />
          <Route
            element={<AdminLoadable.CompanyPage />}
            path={`${AppRoutes.COMPANY}`}
          />

          <Route
            element={<AdminLoadable.MyJobList />}
            path={`${AppRoutes.MY_JOB_LIST}`}
          />
          <Route
            element={<AdminLoadable.EventsList />}
            path={`${AppRoutes.EVENTS_LIST}`}
          />

          <Route
            element={<AdminLoadable.ArticlesList />}
            path={`${AppRoutes.ARTICLES_LIST}`}
          />

          <Route
            element={<AdminLoadable.UsersList />}
            path={`${AppRoutes.USERS_LIST}`}
          />
          <Route
            element={<AdminLoadable.Campaign />}
            path={AppRoutes.CAMPAIGN}
          />
          <Route
            element={<AdminLoadable.Campaign />}
            path={`${AppRoutes.CAMPAIGN}/:type/:id`}
          />
          <Route
            element={<AdminLoadable.Campaign />}
            path={`${AppRoutes.CAMPAIGN}/:type/:id/:isEditable`}
          />
          <Route
            element={<AdminLoadable.CampaignDetail />}
            path={AppRoutes.CAMPAIGN_DETAIL}
          />
          <Route
            element={<AdminLoadable.CampaignDetail />}
            path={`${AppRoutes.CAMPAIGN_DETAIL}/:id`}
          />
          <Route
            element={<AdminLoadable.CampaignList />}
            path={AppRoutes.CAMPAIGNLIST}
          />
          <Route
            element={<AdminLoadable.CampaignList />}
            path={`${AppRoutes.CAMPAIGNLIST}/:type/:id`}
          />

          <Route
            element={<AdminLoadable.JobPostDetail />}
            path={`${AppRoutes.JOB_POST_DETAIL}/:jobId`}
          />

          <Route
            element={<AdminLoadable.CandidatesList />}
            path={`${AppRoutes.CANDIDATES_LIST}/:jobId`}
          />

          <Route
            element={<AdminLoadable.CompanyPage />}
            path={`${AppRoutes.COMPANY}/:slug`}
          />

          <Route
            element={<AdminLoadable.UserAgreement />}
            path={AppRoutes.USER_AGREEMENT}
          />

          <Route
            element={<AdminLoadable.CommunityGuideline />}
            path={AppRoutes.COMMUNITY_GUIDELINES}
          />
          <Route
            element={<AdminLoadable.CookiePolicy />}
            path={AppRoutes.COOKIES_POLICY}
          />
          <Route
            element={<AdminLoadable.PrivacyPolicy />}
            path={AppRoutes.PRIVACY_POLICY}
          />

          <Route element={<UserLoadable.Profile />} path={AppRoutes.PROFILE} />
          <Route
            element={<UserLoadable.Profile />}
            path={`${AppRoutes.USER}/:id`}
          />
          <Route element={<UserLoadable.Network />} path={AppRoutes.NETWORK} />
          <Route
            element={<UserLoadable.InvitationAll />}
            path={AppRoutes.INVITATION_ALL}
          />

          <Route element={<UserLoadable.Jobs />} path={AppRoutes.JOBS} />
          <Route
            element={<UserLoadable.MyJobPost />}
            path={AppRoutes.POST_JOB}
          />

          <Route
            element={<UserLoadable.MyJobPost />}
            path={`${AppRoutes.POST_JOB}/:id`}
          />

          <Route
            element={<UserLoadable.JobDescription />}
            path={`${AppRoutes.JOB_DESCRIPTION}/:id`}
          />

          <Route
            element={<UserLoadable.JobDescription />}
            path={AppRoutes.JOB_DESCRIPTION}
          />
          <Route
            element={<UserLoadable.JobDetails />}
            path={`${AppRoutes.JOB_DETAILS}/:id`}
          />
          <Route
            element={<UserLoadable.ApplicationAll />}
            path={AppRoutes.JOB_APPLICATION}
          />

          <Route
            element={<UserLoadable.SkillQuestions />}
            path={AppRoutes.SKILL_QUESTIONS}
          />
          <Route
            element={<UserLoadable.JobDetails />}
            path={`${AppRoutes.SKILL_QUESTIONS}/:id`}
          />
          <Route element={<UserLoadable.MyItems />} path={AppRoutes.MY_ITEMS} />
          <Route
            element={<UserLoadable.Messages />}
            path={AppRoutes.MESSAGES}
          />
          <Route
            element={<UserLoadable.ChatRoom />}
            path={AppRoutes.CHATROOM}
          />
          <Route
            element={<UserLoadable.Chat />}
            path={`${AppRoutes.CHAT}/:id/:name/:profile`}
          />

          <Route
            element={<UserLoadable.MyProfile />}
            path={AppRoutes.MYPROFILE}
          />
          <Route
            element={<UserLoadable.RightSide />}
            path={AppRoutes.RIGHTSIDE}
          />
          <Route
            element={<UserLoadable.ProfileViewed />}
            path={AppRoutes.PROFILE_VIEWED}
          />
          <Route
            element={<UserLoadable.SponsoredJob />}
            path={AppRoutes.SPONSORED_JOB_APPLICATION}
          />
          <Route
            element={<UserLoadable.Notifications />}
            path={AppRoutes.NOTIFICATIONS}
          />
          <Route
            element={<UserLoadable.UserAgreement />}
            path={AppRoutes.USER_AGREEMENT}
          />

          <Route
            element={<UserLoadable.CommunityGuideline />}
            path={AppRoutes.COMMUNITY_GUIDELINES}
          />
          <Route
            element={<UserLoadable.CookiePolicy />}
            path={AppRoutes.COOKIES_POLICY}
          />
          <Route
            element={<UserLoadable.PrivacyPolicy />}
            path={AppRoutes.PRIVACY_POLICY}
          />
          <Route
            element={<UserLoadable.WriteArticle />}
            path={AppRoutes.WRITE_ARTICLE}
          />
          <Route
            element={<UserLoadable.WriteArticle />}
            path={`${AppRoutes.WRITE_ARTICLE}/:id`}
          />

          <Route
            element={<UserLoadable.SearchAll />}
            path={`${AppRoutes.SEARCH_ALL}/:query`}
          />
          <Route
            element={<UserLoadable.SearchPostAll />}
            path={`${AppRoutes.SEE_ALL_RESULT}/:type/:query`}
          />
          <Route
            element={<UserLoadable.SearchPostDetails />}
            path={`${AppRoutes.POST}/:type/:id`}
          />
          <Route
            element={<UserLoadable.SearchArticleDetails />}
            path={`${AppRoutes.ARTICLE}/:type/:id`}
          />

          <Route
            element={<UserLoadable.SearchEventDetails />}
            path={`${AppRoutes.EVENT}/:type/:id`}
          />
        </Route>
      ) : (
        <>
          <Route element={<AuthLoadable.Login />} path={AppRoutes.LOGIN} />
          <Route element={<AuthLoadable.SignUp />} path={AppRoutes.SIGNUP} />
          <Route
            element={<AuthLoadable.ForgetPassword />}
            path={AppRoutes.FORGET_PASSWORD}
          />
          <Route
            element={<AuthLoadable.ResetPassword />}
            path={`${AppRoutes.RESET_PASSWORD}/:email`}
          />
          <Route
            element={<AuthLoadable.UserAgreement />}
            path={AppRoutes.USER_AGREEMENT}
          />

          <Route
            element={<AuthLoadable.CommunityGuideline />}
            path={AppRoutes.COMMUNITY_GUIDELINES}
          />
          <Route
            element={<AuthLoadable.CookiePolicy />}
            path={AppRoutes.COOKIES_POLICY}
          />
          <Route
            element={<AuthLoadable.PrivacyPolicy />}
            path={AppRoutes.PRIVACY_POLICY}
          />
        </>
      )}
    </Routes>
  );
}

export default Application;
