import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Dashboard = Loadable({
  loader: () => import("pages/Admin/Dashboard"),
  loading: () => <Spinner />,
});

export const OrgDashboard = Loadable({
  loader: () => import("pages/Admin/OrgDashboard"),
  loading: () => <Spinner />,
});

export const AnalysticsAll = Loadable({
  loader: () => import("pages/Admin/AnalysticsAll"),
  loading: () => <Spinner />,
});

export const UserAgreement = Loadable({
  loader: () => import("components/UserAgreement"),
  loading: () => <Spinner />,
});

export const CommunityGuideline = Loadable({
  loader: () => import("components/CommunityGuideline"),
  loading: () => <Spinner />,
});

export const CookiePolicy = Loadable({
  loader: () => import("components/CookiePolicy"),
  loading: () => <Spinner />,
});

export const PrivacyPolicy = Loadable({
  loader: () => import("components/PrivacyPolicy"),
  loading: () => <Spinner />,
});

export const Pages = Loadable({
  loader: () => import("pages/Admin/CompanyPage"),
  loading: () => <Spinner />,
});
export const CompanyPage = Loadable({
  loader: () => import("pages/Admin/Company"),
  loading: () => <Spinner />,
});
export const MyJobList = Loadable({
  loader: () => import("pages/Admin/MyJobList"),
  loading: () => <Spinner />,
});

export const JobPostDetail = Loadable({
  loader: () => import("pages/Admin/MyJobList/component/JobPostDetail"),
  loading: () => <Spinner />,
});

export const CandidatesList = Loadable({
  loader: () => import("pages/Admin/MyJobList/component/CandidatesList"),
  loading: () => <Spinner />,
});

export const EventsList = Loadable({
  loader: () => import("pages/Admin/MyEvent/component/EventsList"),
  loading: () => <Spinner />,
});

export const ArticlesList = Loadable({
  loader: () => import("pages/Admin/MyArticle/component/ArticlesList"),
  loading: () => <Spinner />,
});

export const UsersList = Loadable({
  loader: () => import("pages/Admin/MyUsers/component/UsersList"),
  loading: () => <Spinner />,
});

export const Campaign = Loadable({
  loader: () => import("pages/Admin/Campaign"),
  loading: () => <Spinner />,
});

export const CampaignList = Loadable({
  loader: () => import("pages/Admin/CampaignList"),
  loading: () => <Spinner />,
});

export const CampaignDetail = Loadable({
  loader: () => import("pages/Admin/CampaignDetail"),
  loading: () => <Spinner />,
});
