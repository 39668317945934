/**
 * @format
 */
const QueryKeys = {
  profile: "user.profile",
  companyProfile: "user.companyProfile",
  companyProfileBySlug: "user.companyProfile",
  countries: "auth.countries",
  states: "auth.states",
  cities: "auth.cities",
  institutes: "auth.institutes",
  organizationType: "auth.organizationType",
  domains: "auth.domains",
  cause: "profile.cause",
  organizations: "profile.organizations",
  degree: "profile.degree",
  language: "profile.language",
  empType: "profile.empType",
  suggestedSkills: "profile.suggestedSkills",
  skills: "profile.skills",
  issuerOrg: "profile.issuerOrg",
  events: "user.event",
  invitations: "network.invitations",
  candidates: "job.candidates",
  invitationsAll: "network.invitationsAll",
  invitationsMy: "network.invitationsMy",
  chatMessages: "chat.chatMessages",
  friends: "network.friends",
  viewers: "profile.viewers",
  unblock: "network.unblock",
  block: "profile.block",
  blockedUsers: "network.blockedUsers",
  suggestions: "network.suggestions",
  allSkills: "profile.all_skills",
  allCertifications: "profile.allCertifications",
  allExp: "profile.allExp",
  allVolunteerExp: "profile.allVolunteerExp",
  allPublications: "profile.allPublications",
  allProjects: "profile.allProjects",
  allAwards: "profile.allAwards",
  allLanguage: "profile.allLanguage",
  getJobs: "jobs.getJobs",
  jobDetails: "jobs.jobDetails",
  appliedJob: "jobs.appliedJob",
  getOrgJobs: "jobs.getOrgJobs",
  getOrgUsers: "users.getOrgUsers",
  jobStatus: "jobs.jobStatus",
  pageDetails: "page.pageDetails",
  eventDetails: "event.eventDetails",
  articleDetails: "article.articleDetails",
  orgTimeline: "org.orgTimeline",
  timeline: "org.timeline",
  orgArticles: "org.orgArticles",
  searchData: "search.data",
  searchSee: "search.see",
  userPost: "user.post",
  orgDetail: "org.detail",
  articalDetail: "org.articalDetail",
  addComment: "org.addComment",
  connections: " users.connections",
  viewerCount: " profile.viewerCount",
  notification: "user.notification",
  orgUserList: "org.usersList",
  campaignDetail: "org.campaignDetail",
  targetAudience: "org.targetAudience",
  orgCampaignList: "org.campaignList",
  notificationCount: "notificationCount",
  sponsoredItem: "sponsoredItem",
  orgCampaignDetail: "org.particularCampaignDetail",
  typeDetails: "typeDetails",
  typeCampaign: "typeCampaign",
  inboxList: "inboxList",
  itemUserPosts: "user.itemUserPosts",
  useritemArticles: "user.userItemArticles",
  eventList: "eventList",
  likedPages: "user.likedPages",
  searchList: "searchList",
};

export type QueryKeysType = keyof typeof QueryKeys;

export { QueryKeys };
