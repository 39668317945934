import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { FIREBASE_CONFIG, KEY_PAIR } from "./constants";

const app = initializeApp(FIREBASE_CONFIG);

const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: KEY_PAIR })
    .then((currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
      }
    })
    .catch((err) => {});
};

export const deleteFCMToken = () => {
  return deleteToken(messaging);
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
