import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

const initialState: any = {
  countView: 0,
  newPageId: 0,
};

interface IPayload {
  countView?: number;
  newPageId?: number;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const authSlice = createSlice({
  name: "countView",
  initialState,
  reducers: {
    setViewCount: (state, action: IAction) => {
      const newState = state;
      newState.countView = action.payload.countView;
    },
    setNewPageId: (state, action: IAction) => {
      const newState = state;
      newState.newPageId = action.payload.newPageId;
    },
  },
});

export const { setViewCount, setNewPageId } = authSlice.actions;

export const useViewCountActions = () => {
  const dispatch = useAppDispatch();
  return {
    setViewCount: (payload: IPayload) => dispatch(setViewCount(payload)),
    setNewPageId: (payload: IPayload) => dispatch(setNewPageId(payload)),
  };
};
const { reducer } = authSlice;
export default reducer;
