import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Profile = Loadable({
  loader: () => import("pages/User/Profile"),
  loading: () => <Spinner />,
});

export const Network = Loadable({
  loader: () => import("pages/User/Network"),
  loading: () => <Spinner />,
});

export const Jobs = Loadable({
  loader: () => import("pages/User/Jobs"),
  loading: () => <Spinner />,
});

export const MyJobPost = Loadable({
  loader: () => import("pages/Admin/MyJobPost"),
  loading: () => <Spinner />,
});

export const JobDescription = Loadable({
  loader: () => import("pages/Admin/MyJobPost/component/JobDescription"),
  loading: () => <Spinner />,
});

export const JobDetails = Loadable({
  loader: () => import("pages/User/JobDetails"),
  loading: () => <Spinner />,
});

export const SkillQuestions = Loadable({
  loader: () => import("pages/Admin/MyJobPost/component/SkillQuestions"),
  loading: () => <Spinner />,
});

export const MyItems = Loadable({
  loader: () => import("pages/User/MyItems"),
  loading: () => <Spinner />,
});

export const Messages = Loadable({
  loader: () => import("pages/User/Messages"),
  loading: () => <Spinner />,
});

export const ChatRoom = Loadable({
  loader: () => import("pages/User/ChatRoomResponsive"),
  loading: () => <Spinner />,
});
export const Chat = Loadable({
  loader: () =>
    import(
      "pages/User/Messages/component/LeftSide/component/ChatRoom/component/Chat"
    ),
  loading: () => <Spinner />,
});
export const MyProfile = Loadable({
  loader: () =>
    import("pages/User/Messages/component/RightSide/component/MyProfile"),
  loading: () => <Spinner />,
});
export const RightSide = Loadable({
  loader: () => import("pages/User/Messages/component/RightSide"),
  loading: () => <Spinner />,
});

export const Notifications = Loadable({
  loader: () => import("pages/User/Notifications"),
  loading: () => <Spinner />,
});

export const UserAgreement = Loadable({
  loader: () => import("components/UserAgreement"),
  loading: () => <Spinner />,
});

export const CommunityGuideline = Loadable({
  loader: () => import("components/CommunityGuideline"),
  loading: () => <Spinner />,
});

export const CookiePolicy = Loadable({
  loader: () => import("components/CookiePolicy"),
  loading: () => <Spinner />,
});

export const PrivacyPolicy = Loadable({
  loader: () => import("components/PrivacyPolicy"),
  loading: () => <Spinner />,
});

export const InvitationAll = Loadable({
  loader: () => import("pages/User/Network/components/InvitationAll"),
  loading: () => <Spinner />,
});

export const WriteArticle = Loadable({
  loader: () => import("pages/User/Network/components/WriteArticle"),
  loading: () => <Spinner />,
});

export const ApplicationAll = Loadable({
  loader: () => import("pages/User/ApplicationAll"),
  loading: () => <Spinner />,
});

export const SearchAll = Loadable({
  loader: () => import("pages/User/SearchAll"),
  loading: () => <Spinner />,
});

export const SearchPostAll = Loadable({
  loader: () => import("pages/User/SearchAll/SearchPostAll"),
  loading: () => <Spinner />,
});

export const SearchPostDetails = Loadable({
  loader: () => import("pages/User/PostsDetail/PostDetails"),
  loading: () => <Spinner />,
});

export const SearchArticleDetails = Loadable({
  loader: () => import("pages/User/ArticlesDetail/ArticlesDetail"),
  loading: () => <Spinner />,
});

export const SearchEventDetails = Loadable({
  loader: () => import("pages/User/EventsDetail/EventsDetail"),
  loading: () => <Spinner />,
});

export const ProfileViewed = Loadable({
  loader: () => import("pages/User/ProfileViewed"),
  loading: () => <Spinner />,
});

export const SponsoredJob = Loadable({
  loader: () => import("pages/User/SponsoredAllJob"),
  loading: () => <Spinner />,
});
