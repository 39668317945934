import { CardContent, Card as MUICard } from "@mui/material";
import { ReactNode } from "react";
import palette from "theme/palette";

interface IProps {
  children: ReactNode;
  styleContent?: any;
  styleCard?: any;
  borderRadius?: any;
  id?: string;
  bgColor?: string;
}

function Card(props: IProps) {
  const { children, styleContent, borderRadius, id, styleCard, bgColor } =
    props;
  return (
    <MUICard
      id={id}
      sx={{
        background: bgColor || palette.common.white,
        boxShadow: " 4px 4px 7px rgba(97, 95, 95, 0.25)",
        borderRadius: borderRadius || "4px",
        margin: "0px 0px 6px 0px",
        ...styleCard,
      }}
    >
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: "1rem",
          },
          ...styleContent,
        }}
      >
        {children}
      </CardContent>
    </MUICard>
  );
}

export default Card;
