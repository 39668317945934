import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import Card from "components/Card";
import React from "react";
import palette from "theme/palette";

type IProps = {
  open: boolean;
  title: string;
  type: AlertColor;
  handleClose: () => void;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

function Snackbar(props: IProps) {
  const { open, title, type, handleClose } = props;

  return (
    <Box
      sx={{
        display: open ? "block" : "none",
        position: "fixed",
        bottom: 10,
        left: 10,
        zIndex: 999999,
      }}
    >
      <Card
        borderRadius="6px"
        styleCard={{ marginBottom: "12px" }}
        styleContent={{
          boxShadow: "2.00774px 1.20464px 2.00774px rgba(0, 0, 0, 0.1)",
          padding: 0,
          "&:last-child": {
            paddingBottom: "0",
          },
        }}
      >
        <Stack
          alignItems="center"
          borderRadius="10px"
          direction="row"
          justifyContent="space-between"
        >
          <Box
            sx={{
              backgroundColor:
                type === "error" ? palette.error.main : palette.success.main,
              width: "10px",
              height: "45px",
              py: "0px",
            }}
          />
          <Box p="10px">
            <Typography
              sx={{ marginTop: "8px", fontSize: "13px", pl: "10px" }}
              variant="subtitle3SemiBold"
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
}

export default Snackbar;
