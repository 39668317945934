import Loadable from "react-loadable";

import { Spinner } from "components/Loader";

export const Login = Loadable({
  loader: () => import("pages/Auth/Login"),
  loading: () => <Spinner />,
});

export const SignUp = Loadable({
  loader: () => import("pages/Auth/Signup"),
  loading: () => <Spinner />,
});

export const ForgetPassword = Loadable({
  loader: () => import("pages/Auth/ForgetPassword"),
  loading: () => <Spinner />,
});

export const ResetPassword = Loadable({
  loader: () => import("pages/Auth/ResetPassword"),
  loading: () => <Spinner />,
});

export const UserAgreement = Loadable({
  loader: () => import("components/UserAgreement"),
  loading: () => <Spinner />,
});

export const CommunityGuideline = Loadable({
  loader: () => import("components/CommunityGuideline"),
  loading: () => <Spinner />,
});

export const CookiePolicy = Loadable({
  loader: () => import("components/CookiePolicy"),
  loading: () => <Spinner />,
});

export const PrivacyPolicy = Loadable({
  loader: () => import("components/PrivacyPolicy"),
  loading: () => <Spinner />,
});
