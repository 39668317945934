const AppRoutes = {
  LOGIN: "/",
  SIGNUP: "/signup",
  FORGET_PASSWORD: "/forgetpassword",
  RESET_PASSWORD: "/resetpassword",
  DASHBOARD: "/",
  ORG_DASHBOARD: "/",
  ANALYSTICS_ALL: "/all-analystics",
  NETWORK: "/network",
  INVITATION_ALL: "/network/invitation",
  JOBS: "/jobs",
  JOB_DESCRIPTION: "/jobdescription",
  JOB_APPLICATION: "/jobapplication",
  SPONSORED_JOB_APPLICATION: "/sponsoredJobs",
  EVENTS_LIST: "/events-list",
  ARTICLES_LIST: "/articles-list",
  USERS_LIST: "/users-list",
  JOB_DETAILS: "/jobdetails",
  SKILL_QUESTIONS: "/skillquestions",
  POST_JOB: "/post-job",
  CHAT: "/chat",
  MESSAGES: "/messages",
  CHATROOM: "/chatroom",
  MYPROFILE: "/myprofile",
  RIGHTSIDE: "/RightSide",
  PROFILE_VIEWED: "/profile-viewed",
  PROFILE: "/profile",
  USER: "/user",
  NOTIFICATIONS: "/notifications",
  EVENTS: "/events",
  MY_ITEMS: "/my-items",
  COMPANY_PAGE: "/page",
  COMPANY: "/company",
  MY_JOB_LIST: "/my-job-list",
  JOB_POST_DETAIL: "/job-post-detail",
  CANDIDATES_LIST: "/candidates-list",
  // footer
  USER_AGREEMENT: "/user-agreement",
  PRIVACY_POLICY: "/privacy-policy",
  COMMUNITY_GUIDELINES: "/community-guidelines",
  COOKIES_POLICY: "/cookies-policy",
  COPYRIGHT_POLICY: "/copyright-policy",
  WRITE_ARTICLE: "/post-new",
  // USERS_LIST: "/users-list",
  CAMPAIGN: "/campaign",
  CAMPAIGN_DETAIL: "/campaign-detail",
  CAMPAIGNLIST: "/campaign-list",
  SEARCH_ALL: "/search-all",
  SEE_ALL_RESULT: "/see-all-result",
  POST: "/post",
  ARTICLE: "/article",
  EVENT: "/event",
};

export default AppRoutes;
